html {
    height: 100%;
}

body {
    font-weight: 400;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    height: 100%;
}

.d-inline-flex {
    display: inline-flex !important;
}

.bg-dark-gray {
    background-color: #323539;
}

.cursor-default {
    cursor: default;
}

.main-footer {
    height: 2.2rem;
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display: none;
    }
}

@media (max-width: 1024px) {
    .main-footer {
        height: auto !important;
    }
    .hide-on-tablet {
        display: none;
    }
    .job-title {
        margin: 1.2rem 0.6rem !important;
    }
}

.main-footer .copyright {
    color: #323539;
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
}

.float-right {
    float: right;
    margin-right: 1rem;
}

.card {
    border-radius: .1rem;
    box-shadow: none;
    border: 2px solid #e1e5eb;
}

.card-small {
    box-shadow: none;
    border: 2px solid #e1e5eb;
}

.transparent {
    background-color: transparent !important;
    border: none !important;
}

.btn-primary.transparent:hover, .btn-primary.transparent:focus, .btn-primary.transparent:active {
    background-color: transparent !important;
    border: none !important;
    outline: none;
}

.transparent:hover i, .transparent:focus i, .transparent:active i {
    color: #E19229;
    font-size: 3rem;
}

.transparent i {
    color: #868e96;
    font-size: 3rem;
}

.no-border {
    border: none !important;
}

.btn-white {
    border: 1rem solid #e1e5eb;
}

.btn-accent:hover {
    color: #fff;
    background-color: #818EA3;
    border-color: #818EA3;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #3C5490 !important;
    border-color: #3C5490 !important;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #fff;
    background-color: #5a6169 !important;
    border-color: #5a6169 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05) !important;
}

.center-block {
    transform: translate3d(0%, 7%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 540px;
}

.full-page {
    background-color: #ebeff3;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #333333 !important;
    background-color: #E8EDF1 !important;
    border: none;
    outline: none !important
}